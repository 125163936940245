import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';

export default class extends Controller {
  connect() {
    console.log("Tippy Click Controller connected");
    console.log(this.element);
    console.log(this.element.getAttribute('data-tippy-content'));
    tippy(this.element, {
      content: this.element.getAttribute('data-tippy-content'),
      theme: 'custom',
      arrow: true,
      animation: 'fade',
      trigger: 'click', // Change this from 'mouseenter' to 'click'
      interactive: true, // Allow interaction with tooltip content
      hideOnClick: false, // Prevent hiding when clicking inside the tooltip
    });
  }
}
